import React from "react";
import { Link } from "gatsby";

const Nav = () => (
	<nav className="bg-yellow-cust1">
		<div className="flex p-5 lg:p-10 items-center h-12 lg:h-20">
			<div className="font-baloo text-2xl lg:text-5xl text-shadow">
				<Link to="/" className="text-white no-underline">
					juzycook
				</Link>
			</div>
			<svg
				className="w-5 h-5 lg:w-10 lg:h-10 ml-1 lg:ml-3 fill-current text-white"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 45 45"
			>
				<path d="M43.604 41.146c.703.64.945 2.207.037 3.107-.902.909-2.472.668-3.109-.034-5.893-6.328-11.783-12.659-17.676-18.989l-.67-.715C16.022 31.133 9.86 37.754 3.698 44.375c-.635.701-2.202.943-3.104.034-.91-.901-.67-2.472.032-3.109 6.642-6.178 13.279-12.358 19.92-18.539 0 0-3.214-3.467-4.6-4.955-3.243 2.325-8.049 1.441-10.938-2.126C1.901 11.917-.779 5.187 1.815 2.431c2.759-2.596 9.485.09 13.248 3.194 3.566 2.892 4.455 7.691 2.125 10.936 1.67 1.556 3.34 3.108 5.012 4.663l-.01.008s.104.082.01-.008l4.688-4.364c-1.32-2.254-.443-5.415 1.701-7.219C31.048 7.58 40.159-.242 40.404.006c.244.243-6.146 6.281-10.415 10.563l.712.711c4.557-3.994 10.99-9.984 11.238-9.74.244.245-5.946 6.48-10.076 10.905l.707.705C36.988 9.017 43.225 2.826 43.467 3.069c.245.246-5.746 6.68-9.74 11.237l.707.706c4.28-4.269 10.315-10.659 10.562-10.417.247.246-9.618 11.805-9.632 11.822-.002-.002 2.094-2.462-.008-.006-2.006 2.347-5.002 3.057-7.217 1.705-1.438 1.543-2.873 3.086-4.309 4.629-.126-.119 13.184 12.268 19.774 18.401z" />
			</svg>
		</div>
	</nav>
);

export default Nav;
