import React from "react";
import PropTypes from "prop-types";

import Seo from "./seo";
import Nav from "./nav";
import Footer from "./footer";
import "../assets/css/main.css";

const Layout = ({ title, description, imagePath, children }) => {
	return (
		<>
			<Seo
				title={title}
				description={description}
				imagePath={imagePath}
			/>
			<Nav />
			<main>{children}</main>
			<Footer />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
