import React from "react";

const Footer = () => (
	<div className="h-48 bg-gray-800">
		<div className="flex h-24 text-center items-center">
			<div className="text-sm text-white mx-auto">
				Copyright© <a href="https://www.juzy.io">Juzy.io</a>. All Rights
				Reserved.
			</div>
		</div>
	</div>
);

export default Footer;
